import {
  FLIGHT_ROUTES,
  suggestionToITripTerminus,
  terminusToSuggestion,
} from "@b2bportal/core-utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { useTenantIcons } from "@hopper-b2b/utilities";
import {
  CalendarPickerButton,
  PassengerPickerInput,
  FlightCalendarPicker,
} from "@lloyds/ui-connected";
import { ActionButton, RadioGroup } from "@lloyds/ui-core";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { FlightLocationAutoComplete } from "../FlightLocationAutocomplete";
import styles from "./DesktopFlightSearch.module.scss";
import { FlightSearchHooks, useFlightSearch } from "@components/flights";
import { useLocation } from "react-router-dom-v5-compat";

export const DesktopFlightSearch = () => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const {
    context: {
      tripCategory,
      passengerCounts,
      origin,
      destination,
      departureDate,
      returnDate,
      validSearchParams,
    },
    handlers: {
      onDepartureDateChange,
      onDestinationChange,
      onOriginChange,
      onPassengerCountChange,
      onReturnDateChange,
      onTripCategoryChange,
      onSearch,
    },
  } = useFlightSearch();

  const [rotation, setRotation] = useState<number>(0);
  const location = useLocation();

  const tripOptions = [
    { label: t("flightRoundTrip"), value: TripCategory.ROUND_TRIP },
    { label: t("flightOneWay"), value: TripCategory.ONE_WAY },
  ];
  const isReadyToSearch = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? !!departureDate && !!origin
      : tripCategory === TripCategory.ROUND_TRIP
      ? !!returnDate && !!departureDate && !!origin && !!destination
      : false;
  }, [tripCategory, destination, origin, returnDate, departureDate]);

  const handleSearch = useCallback(() => {
    onSearch(validSearchParams);
  }, [validSearchParams, onSearch]);

  const handleSwitch = useCallback(() => {
    onOriginChange(destination);
    onDestinationChange(origin);
    setRotation((prevRotation) => prevRotation + 180);
  }, [onOriginChange, onDestinationChange, setRotation, destination, origin]);

  const { departureMonths, priceTags } = FlightSearchHooks.useFetchCalendar({
    origin,
    destination,
    tripCategory,
  });

  const isSearchRoute = useMemo(() => {
    return location.pathname.includes(FLIGHT_ROUTES.SEARCH_ROUTE);
  }, [location]);

  return (
    <>
      {isSearchRoute && (
        <Box marginBottom={4} marginTop={4}>
          <Typography variant="h3">
            {t("flightsPageTitles.searchFlights")}
          </Typography>
        </Box>
      )}

      <Grid container spacing={3} direction="row" alignItems="stretch">
        <Grid item xs={6} sm={3} lg={3}>
          <RadioGroup
            value={tripCategory}
            options={tripOptions}
            onChange={onTripCategoryChange}
            className={styles.radioGroup}
            containerClassName={styles.radioInputContainer}
            radioClassName={styles.radioLabel}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <PassengerPickerInput
            paxCounts={passengerCounts}
            updatePaxCounts={onPassengerCountChange}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid container item xs={6} style={{ position: "relative" }}>
          <Grid item xs={6}>
            <FlightLocationAutoComplete
              id="origin"
              label=""
              placeholder={t("searchControl.whereFrom")}
              onChange={(sug) => onOriginChange(suggestionToITripTerminus(sug))}
              defaultValue={origin && terminusToSuggestion(origin)}
              className={styles.airAutocomplete}
              icon={
                <img
                  src={icons.locationMarker}
                  alt=""
                  className={styles.autocompleteIcon}
                />
              }
              additionalSearchControl={{
                activeControl: "origin",
                destination: origin?.id?.code?.code ?? undefined,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FlightLocationAutoComplete
              id="destination"
              label=""
              placeholder={t("searchControl.whereTo")}
              onChange={(sug) =>
                onDestinationChange(suggestionToITripTerminus(sug))
              }
              defaultValue={destination && terminusToSuggestion(destination)}
              className={styles.airAutocomplete}
              icon={<img src={icons.locationMarker} alt="" />}
              additionalSearchControl={{
                activeControl: "destination",
                destination: destination?.id?.code?.code ?? undefined,
              }}
            />
          </Grid>

          <div className={styles.switchContainer}>
            <IconButton onClick={handleSwitch}>
              <img
                src={icons.switch}
                alt="switch-destination-origin"
                style={{
                  transform: `rotate(${rotation}deg)`,
                }}
                className={styles.switchIcon}
              />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <CalendarPickerButton
            startDate={departureDate ?? null}
            endDate={returnDate ?? null}
            tripCategory={tripCategory}
            startLabel={t("departure") ?? undefined}
            endLabel={t("return") ?? undefined}
            setStartDate={onDepartureDateChange}
            setEndDate={onReturnDateChange}
            CalendarComponent={FlightCalendarPicker}
            priceTags={priceTags}
            departureMonths={departureMonths}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Box height="100%" display={"flex"}>
            <ActionButton
              onClick={handleSearch}
              disabled={!isReadyToSearch}
              size="medium"
              message={t("searchButton")}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
