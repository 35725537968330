import {
  FlightSearchProps as CommonFlightSearchProps,
  HotelSearchProps as CommonHotelSearchProps,
  FeatureFlags,
  FeatureFlagsProvider,
  TabType,
  Tabs,
  useIsRoomsEnabled,
} from "@hopper-b2b/common-search";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useAirEnabled, useLodgingEnabled } from "@lloyds/theming";
import {
  PATH_HOME,
  useDeviceTypes,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import styles from "./Search.module.scss";
import {
  FlightSearchButton,
  HotelSearch,
  HotelSearchButton,
  DesktopHotelSearch,
} from "./components";
import { Box, Typography } from "@material-ui/core";
import { FlightSearch } from "./components/flights/FlightSearch";

export const HOTELS_TAB_VALUE = "hotel-tab" as const;
export const FLIGHTS_TAB_VALUE = "flights-tab" as const;

export const enum SearchType {
  AIR,
  LODGING,
}

type BaseSearchProps = {
  type?: SearchType;
  enableAir?: boolean;
  enableLodging?: boolean;

  onSearch?: (path: string) => void;
};

type AirSearchProps = CommonFlightSearchProps &
  BaseSearchProps & {
    type: SearchType.AIR;
    enableAir: true;
    enableLodging: false;
  };

type LodgingSearchProps = CommonHotelSearchProps &
  BaseSearchProps & {
    type: SearchType.LODGING;
    enableAir: true;
    enableLodging: false;
  };

export type SearchProps = AirSearchProps | LodgingSearchProps | BaseSearchProps;

const isAirSearch = (searchProps: SearchProps): searchProps is AirSearchProps =>
  searchProps.type === SearchType.AIR;

const isLodgingSearch = (
  searchProps: SearchProps
): searchProps is LodgingSearchProps => searchProps.type === SearchType.LODGING;

const TabbedSearch = ({
  enableAir: enableAirProp = true,
  enableLodging: enableLodgingProp = true,
  onSearch,
}: BaseSearchProps) => {
  const { t } = useI18nContext();

  const enableAir = useAirEnabled() !== false && enableAirProp;
  const enableLodging = useLodgingEnabled() !== false && enableLodgingProp;
  const enableRooms = useIsRoomsEnabled();
  const icons = useTenantIcons();

  const { matchesMobile } = useDeviceTypes();

  const tabs: TabType[] = useMemo(
    () =>
      [
        enableLodging
          ? {
              value: HOTELS_TAB_VALUE,
              label: t?.("hotels"),
              icon: <img alt="" src={icons.hotels} />,
              panel: matchesMobile ? (
                <HotelSearchButton />
              ) : (
                <DesktopHotelSearch
                  onSearch={onSearch}
                  enableRooms={enableRooms}
                />
              ),
            }
          : undefined,
        enableAir
          ? {
              value: FLIGHTS_TAB_VALUE,
              label: t?.("flights"),
              icon: <img alt="" src={icons.flights} />,
              panel: matchesMobile ? <FlightSearchButton /> : <FlightSearch />,
            }
          : undefined,
      ].filter(Boolean),
    [enableAir, enableLodging, matchesMobile, t, enableRooms, onSearch, icons]
  );

  return (
    <FeatureFlagsProvider
      flags={{
        [FeatureFlags.AIR]: enableAir,
        [FeatureFlags.LODGING]: enableLodging,
        [FeatureFlags.CALENDAR_PREDICTION]: true,
        [FeatureFlags.ROOMS]: false,
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        p={4}
      >
        <SearchTitle
          title={t("homepageSearchTitle")}
          subtitle={t("homepageSearchSubtitle")}
          isMobile={matchesMobile}
        />
        <Tabs
          tabClassName={matchesMobile ? styles.mobileTab : styles.desktopTab}
          tabPanelClassName={styles.tabPanel}
          tabs={tabs}
          containerClassName={styles.tabContainer}
          onSearch={onSearch}
        />
      </Box>
    </FeatureFlagsProvider>
  );
};

const SearchTitle = ({
  title,
  subtitle,
  isMobile,
}: {
  title: string;
  subtitle: string;
  isMobile: boolean;
}) => (
  <>
    <Typography
      variant="subtitle1"
      style={{ color: "var(--white)" }}
      className={
        isMobile ? styles.mobileSearchTitle : styles.desktopSearchTitle
      }
    >
      {title}
    </Typography>
    <Typography
      variant="subtitle1"
      style={{ color: "var(--white)" }}
      className={
        isMobile ? styles.mobileSearchSubtitle : styles.desktopSearchSubtitle
      }
    >
      {subtitle}
    </Typography>
  </>
);

export const Search = ({ onSearch: onSearchProps, ...rest }: SearchProps) => {
  const navigate = useNavigate();

  const onSearch = useCallback(
    (path: string) => {
      if (onSearchProps) return onSearchProps(path);
      navigate(`${PATH_HOME}${path}`);
    },
    [navigate]
  );

  return isAirSearch(rest) ? (
    <FlightSearch />
  ) : isLodgingSearch(rest) ? (
    <HotelSearch onSearch={onSearch} {...rest} />
  ) : (
    <TabbedSearch onSearch={onSearch} {...rest} />
  );
};
