import { Suggestion } from "@b2bportal/lodging-api";
import { HotelSearchProps, useIsRoomsEnabled } from "@hopper-b2b/common-search";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Header } from "@lloyds/header";
import { HotelCalendarPicker } from "@lloyds/ui-connected";
import {
  buildSearchParams,
  placeLabelAndSublabelToUrl,
} from "@hopper-b2b/lodging-utils";
import { GuestsSelection } from "@hopper-b2b/types";
import { PATH_HOTELS_BOOK_AVAILABILITY } from "@hopper-b2b/utilities";
import { Dialog, Hidden } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HotelSearchDestination } from "../HotelSearchDestination";
import "./HotelSearch.scss";
import { DesktopHotelSearch } from "./DesktopHotelSearch";

const INTIAL_LODGING_GUESTS = 2;

export const HotelSearch = ({
  enableRooms: enableRoomsProps = true,
  initialAdultGuests = INTIAL_LODGING_GUESTS,
  initialChildGuests = [],
  initialRooms = 1,
  initialLocation,
  onSearch,
  onOpen,
  initialCheckinDate,
  initialCheckoutDate,
}: HotelSearchProps) => {
  const { t } = useI18nContext();
  const enableRoomsFlag = useIsRoomsEnabled();
  const enableRooms = enableRoomsProps ?? enableRoomsFlag;

  const [isCalendarStep, setIsCalendarStep] = useState(false);

  const [destination, setDestination] = useState<Suggestion | undefined>(
    initialLocation
  );
  const [checkinDate, setCheckinDate] = useState<string | undefined>(
    initialCheckinDate
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    initialCheckoutDate
  );
  const [guestCount, setGuestCount] = useState<GuestsSelection>({
    adults: initialAdultGuests,
    children: initialChildGuests,
    rooms: initialRooms,
  });

  useEffect(() => {
    setDestination(initialLocation);
  }, [initialLocation]);

  const destinationCode = useMemo(() => {
    if (destination) {
      const { label, subLabel } = destination;
      return placeLabelAndSublabelToUrl(label, subLabel);
    }
    return undefined;
  }, [destination]);

  const searchUrl = useMemo(() => {
    const searchParams = buildSearchParams({
      fromDate: checkinDate,
      untilDate: checkoutDate,
      adultsCount: guestCount.adults,
      childrenCount: guestCount.children,
      roomsCount: guestCount.rooms,
      enableRooms,
    });

    return `${PATH_HOTELS_BOOK_AVAILABILITY}${destinationCode}?${searchParams.toString()}`;
  }, [
    guestCount.adults,
    guestCount.children,
    guestCount.rooms,
    checkinDate,
    checkoutDate,
    destinationCode,
    enableRooms,
  ]);

  const handleSearch = useCallback(() => {
    onSearch ? onSearch?.(searchUrl) : (window.location.href = searchUrl);
  }, [onSearch, searchUrl]);

  const onSearchClick = useCallback(() => {
    setIsCalendarStep(true);
  }, []);

  const onCalendarBack = useCallback(() => {
    setIsCalendarStep(false);
  }, []);

  return (
    <>
      <Hidden smDown>
        <DesktopHotelSearch
          initialAdultGuests={initialAdultGuests}
          onSearch={onSearch}
          onOpen={onOpen}
          enableRooms={enableRooms}
        />
      </Hidden>
      <Hidden mdUp>
        <HotelSearchDestination
          enableRooms={enableRooms}
          guestCount={guestCount}
          setGuestCount={setGuestCount}
          initialLocation={initialLocation}
          setDestination={setDestination}
          destination={destination}
          onOpen={onOpen}
          onClick={onSearchClick}
          title={t("lodging.search.selectDestinationTitle")}
        />
        <Dialog
          open={isCalendarStep}
          fullWidth
          fullScreen
          transitionDuration={0}
        >
          <Header title={t("calendarTitle")} onBack={onCalendarBack} />
          <HotelCalendarPicker
            guestCount={guestCount}
            destination={destination}
            startDate={checkinDate}
            endDate={checkoutDate}
            setStartDate={setCheckinDate}
            setEndDate={setCheckoutDate}
            onComplete={handleSearch}
          />
        </Dialog>
      </Hidden>
    </>
  );
};
