import { useCallback, useMemo } from "react";
import { IFlyerOption } from "@hopper-b2b/types";
import { SelectField } from "../SelectField";
import { Box, Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./styles.module.scss";

export interface FrequentFlyerDropdownProps {
  selected: IFlyerOption | null;
  options: IFlyerOption[];
  setOption: (option: IFlyerOption) => void;
}

export const FrequentFlyerPicker = ({
  selected,
  options,
  setOption,
}: FrequentFlyerDropdownProps) => {
  const { t } = useI18nContext();

  const frequentFlyerOptions = useMemo(
    () =>
      options.map((option) => {
        return { value: option.value, label: option?.label || null };
      }),
    [options]
  );

  const handleSetOption = useCallback(
    (optionValue: string) => {
      const newlySelectedOption = options.find(
        (option) => option.value === optionValue
      );
      setOption(newlySelectedOption);
    },
    [options, setOption]
  );

  const getLabel = useCallback(
    (code: string) => {
      return options.find(({ value }) => code === value)?.program;
    },
    [options]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
      <Typography className={styles.title}>
        {t("flight.checkout.travelerInfoForm.chooseProgram")}
      </Typography>
      <SelectField
        options={frequentFlyerOptions}
        setOption={handleSetOption}
        selected={selected ? selected.value : null}
        getLabel={getLabel}
      />
    </Box>
  );
};
