import {
  InitializeRumProps,
  initializeLogs,
  initializeRum,
} from "@hopper-b2b/datadog";
import { config } from "@lloyds/utils";
import { ClientName } from "@hopper-b2b/types";
import { inspect } from "@xstate/inspect";
import { createRoot } from "react-dom/client";
import { Root } from "./Root";
import "./cfar-overrides.scss";
import "./flights-overrides.scss";
import "./global-overrides.scss";
import "./index.scss";
import "./lodging-overrides.scss";
import { getSessionId, maybeLoadMocks } from "./utils";

process.env.NODE_ENV !== "production" &&
  inspect({
    iframe: false,
  });

const sessionId = getSessionId();

// Initialize Datadog Logs
initializeLogs({
  sessionId,
  env: config.env,
  version: config.version,
  service: ClientName.LLOYDS,
});

// Initialize Datadog RUM
setTimeout(() => {
  initializeRum({
    env: config.env,
    version: config.version,
    service: ClientName.LLOYDS,
    enableSessionRecording: true,
  } as InitializeRumProps);
}, 1000);

const renderRoot = () => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(<Root />);
};

maybeLoadMocks().then(renderRoot);
