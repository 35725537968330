import { Search } from "@lloyds/search";
import { useTenantImages } from "@hopper-b2b/utilities";
import { AppBar, Box, Container, Hidden, Toolbar } from "@material-ui/core";
import styles from "./HomeRoute.module.scss";

export const HomeRouteComponent = () => {
  const images = useTenantImages();

  return (
    <Box
      style={{
        backgroundImage: `url(${images?.homepageBackground || ""})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Hidden smDown>
        <Box className={styles.desktopContainer}>
          <Container maxWidth="xl" disableGutters>
            <Box className={styles.desktopSearch}>
              <Search />
            </Box>
          </Container>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={styles.mobileContainer}>
          <Container disableGutters maxWidth="xl">
            <Search />
          </Container>
        </Box>
      </Hidden>
    </Box>
  );
};
