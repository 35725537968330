import { Lodgings, Suggestion, Place } from "@b2bportal/lodging-api";
import { fetchLocations } from "@hopper-b2b/common-search";
import { Autocomplete, AutocompleteProps } from "@lloyds/ui-connected";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { ReactComponent as Location } from "../../../../assets/icons/location.svg";
import { ReactComponent as Hotel } from "../../../../assets/icons/hotel.svg";
import { useI18nContext } from "@hopper-b2b/i18n";

enum SuggestionTypeEnum {
  Country = "country",
  City = "locality",
  Lodging = "lodging",
  Other = "other",
}

export interface HotelLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
  > {
  defaultValue?: Suggestion;
}

export const HotelLocationAutoComplete = ({
  id,
  defaultValue,
  label,
  onChange,
  onOpen,
  className,
  placeholder,
  closeIcon,
}: HotelLocationAutoCompleteProps) => {
  const icons = useTenantIcons();
  const { t } = useI18nContext();

  return (
    <>
      <label htmlFor={id} id={`${id}-label`} style={{ display: "none" }}>
        {t("searchDestination")}
      </label>
      <Autocomplete
        id={id}
        key={defaultValue ? "destination-loaded" : "loading-destination"}
        paperClassName={"hotel"}
        defaultValue={defaultValue}
        label={label}
        placeholder={placeholder}
        closeIcon={closeIcon}
        className={className}
        icon={<img src={icons.locationMarker} alt="" />}
        onOpen={onOpen}
        sortOptions={sortLocations}
        onChange={onChange}
        fetch={fetchLocations}
        getOptionLabel={getOptionLabel}
        getOptionSublabel={getOptionSublabel}
        getOptionLabelIcon={getOptionLabelIcon}
      />
    </>
  );
};

const groupPlaceType = (value?: Suggestion): string => {
  const lodging = value?.id as Lodgings;
  const place = lodging?.lodgingSelection as Place;
  return place?.placeTypes?.[0] ?? "";
};

const getOptionLabel = (value?: Suggestion) => {
  return value?.label;
};

const getOptionSublabel = (value?: Suggestion) => {
  return value?.subLabel;
};

const getOptionLabelIcon = (value: Suggestion) => {
  switch (groupPlaceType(value)) {
    case SuggestionTypeEnum.Lodging:
      return <Hotel />;
    default:
      return <Location />;
  }
};

const sortLocations = (options?: Suggestion[]): Suggestion[] => {
  const groupEntries = {
    [SuggestionTypeEnum.Country]: [],
    [SuggestionTypeEnum.City]: [],
    [SuggestionTypeEnum.Lodging]: [],
    [SuggestionTypeEnum.Other]: [],
  };

  options?.forEach((suggestion: Suggestion) => {
    const group: string = groupPlaceType(suggestion);
    groupEntries[group]
      ? groupEntries[group].push(suggestion)
      : groupEntries[SuggestionTypeEnum.Other].push(suggestion);
  });

  return [
    ...groupEntries[SuggestionTypeEnum.Country],
    ...groupEntries[SuggestionTypeEnum.City],
    ...groupEntries[SuggestionTypeEnum.Lodging],
    ...groupEntries[SuggestionTypeEnum.Other],
  ];
};
