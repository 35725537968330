import {
  FLIGHTS_SHOP_REVIEW_FARE_PATH,
  FLIGHTS_SHOP_REVIEW_PATH,
} from "@b2bportal/core-utilities";
import { CheckoutQueryParams, ParentState } from "@hopper-b2b/checkout-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PATH_FLIGHTS_BOOK,
  PATH_FLIGHTS_SEARCH,
  PATH_HOME,
  PATH_HOTELS_BOOK,
  PATH_HOTELS_BOOK_AVAILABILITY,
  PATH_HOTELS_SEARCH,
  PATH_TRIPS,
} from "@hopper-b2b/utilities";
import { PATH_CFAR_PAGE, PATH_DISRUPTION_PAGE } from "@lloyds/utils";
import { useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom-v5-compat";

export const HEADER_TITLE_TEST_ID = "header-title-test-id";

export const HeaderTitle = () => {
  const { t } = useI18nContext();

  return (
    <Routes>
      {/* Flights */}
      <Route
        path={`${PATH_HOME}${PATH_FLIGHTS_SEARCH}`}
        element={t("flight.search.header")}
      />
      <Route
        path={FLIGHTS_SHOP_REVIEW_PATH}
        element={t("flightsPageTitles.reviewItinerary")}
      />
      <Route
        path={FLIGHTS_SHOP_REVIEW_FARE_PATH}
        element={t("flightsPageTitles.fareDetails")}
      />
      {/* Flights Fintech */}
      <Route path={PATH_DISRUPTION_PAGE} element={t("customizeYourTrip")} />
      <Route path={PATH_CFAR_PAGE} element={t("customizeYourTrip")} />
      {/* Hotels */}
      <Route
        path={`${PATH_HOME}${PATH_HOTELS_SEARCH}`}
        element={t("lodging.search.header")}
      />
      <Route
        path={`${PATH_HOME}${PATH_HOTELS_BOOK_AVAILABILITY}*`}
        element={t("lodging.availability.headerTitle")}
      />
      {/* Checkout Titles */}
      <Route
        path={`${PATH_HOME}${PATH_FLIGHTS_BOOK}*`}
        Component={CheckoutHeaderTitle}
      />
      <Route
        path={`${PATH_HOME}${PATH_HOTELS_BOOK}*`}
        Component={CheckoutHeaderTitle}
      />
      <Route
        path={`${PATH_HOME}${PATH_TRIPS}*`}
        element={t("mytrips.headerTitle")}
      />
    </Routes>
  );
};

const CheckoutHeaderTitle = () => {
  const { t } = useI18nContext();

  const { search } = useLocation();
  const checkoutState = new URLSearchParams(search).get(
    CheckoutQueryParams.checkoutState
  );

  const title = useMemo(() => {
    switch (checkoutState) {
      case ParentState.passengerInformation:
      case ParentState.passport:
        return t("travelerInformation");
      case ParentState.cardPayment:
        return t("paymentInformation");
      case ParentState.review:
        return t("reviewDetails");
      case ParentState.bookingConfirmation:
        return null;
      default:
        return t(checkoutState);
    }
  }, [checkoutState, t]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{title}</>;
};
