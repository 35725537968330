import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BButton } from "@hopper-b2b/ui";
import { PATH_HOME, PATH_HOTELS_SEARCH } from "@hopper-b2b/utilities";
import { Grid, Typography } from "@material-ui/core";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import styles from "./HotelSearchButton.module.scss";

export const HotelSearchButton = () => {
  const { t } = useI18nContext();
  const navigate = useNavigate();

  const handleSearch = useCallback(() => {
    navigate(`${PATH_HOME}${PATH_HOTELS_SEARCH}`);
  }, [navigate]);

  // TODO: replace with text input field once that has been implemented
  return (
    <B2BButton variant="traveler-selector" onClick={handleSearch}>
      <Grid container alignItems="center" spacing={4} wrap="nowrap">
        <Grid item>
          <SearchIcon className={styles.searchIcon} />
        </Grid>
        <Grid item>
          <Typography variant="body2" color="primary">
            {t("hotelSearchPrompt")}
          </Typography>
        </Grid>
      </Grid>
    </B2BButton>
  );
};
