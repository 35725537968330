import { ExperimentsProvider } from "@hopper-b2b/experiments";
import I18nProvider, {
  enGB_defaultTranslations,
  enGB_lloydsTranslations,
  en_defaultTranslations,
  en_lloydsTranslations,
} from "@hopper-b2b/i18n";
import { Translation, TranslationLanguage } from "@hopper-b2b/types";
import {
  TenantContextProvider,
  UserSourceProvider,
  apiConfig,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import {
  AxiosInterceptorWrapper,
  IAxiosInterceptors,
  useUserSessionBrand,
} from "@lloyds/auth";
import {
  ThemingProvider,
  branding,
  generateTenantContext,
} from "@lloyds/theming";
import { config } from "@lloyds/utils";
import { WalletProvider } from "@lloyds/wallet";
import { merge } from "lodash-es";
import { PropsWithChildren, useMemo } from "react";

export const DEFAULT_LANG = "en-GB";

export type LloydsProvidersProps = Omit<IAxiosInterceptors, "version">;

export const LloydsProviders = ({
  ...props
}: PropsWithChildren<LloydsProvidersProps>) => (
  <LloydsProvidersInternal {...props} />
);

export const LloydsI18nProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>) => {
  const tenantTranslation: Translation = useMemo(() => {
    return {
      [TranslationLanguage.en]: merge(
        en_defaultTranslations,
        en_lloydsTranslations
      ),
      [TranslationLanguage["en-GB"]]: merge(
        enGB_defaultTranslations,
        enGB_lloydsTranslations
      ),
    };
  }, []);

  return (
    <I18nProvider
      defaultLng={branding.default}
      branding={branding}
      tenantTranslation={tenantTranslation}
    >
      {children}
    </I18nProvider>
  );
};

const LloydsProvidersInternal = ({
  children,
}: PropsWithChildren<Omit<LloydsProvidersProps, "brand">>) => {
  const isAuthenticated = useIsSessionAuthenticated();
  const brand = useUserSessionBrand();

  return (
    <LloydsI18nProvider>
      <TenantContextProvider tenantContext={generateTenantContext(brand)}>
        <UserSourceProvider>
          <ExperimentsProvider
            apiConfig={apiConfig}
            isLoggedIn={isAuthenticated}
          >
            <AxiosInterceptorWrapper version={config.version}>
              <WalletProvider>
                <ThemingProvider brand={brand}>{children}</ThemingProvider>
              </WalletProvider>
            </AxiosInterceptorWrapper>
          </ExperimentsProvider>
        </UserSourceProvider>
      </TenantContextProvider>
    </LloydsI18nProvider>
  );
};
