import { disruptionRootReducer } from "@b2bportal/core-disruption";
import { flightsRootReducer } from "@b2bportal/core-flights";
import { cfarRootReducer } from "@b2bportal/core-flights-cfar";
import { FeatureFlagsContextProvider } from "@b2bportal/core-utilities";
import { PortalCore } from "@hopper-b2b/portal";
import { AgentImpersonation, authReducer } from "@hopper-b2b/self-serve";
import { SlotProvider } from "@hopper-b2b/ui";
import {
  PATH_AGENT_IMPERSONATION,
  PATH_AUTH,
  PATH_HOME,
} from "@hopper-b2b/utilities";
import { AuthRoutes } from "@lloyds/auth";
import { LloydsProviders } from "@lloyds/common";
import { Footer } from "@lloyds/footer";
import { Header } from "@lloyds/header";
import { LoadingScreen } from "@lloyds/ui-connected";
import { PATH_FAQ, PATH_SUPPORT, PATH_TERMS } from "@lloyds/utils";
import { Box } from "@material-ui/core";
import { configureStore } from "@reduxjs/toolkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import config from "../../config";
import { AppRoutes } from "../Routes";
import styles from "./App.module.scss";
import { APP_SLOTS } from "./AppSlots";
import { ErrorBoundary } from "./ErrorBoundary";

const TermsRouteComponent = lazy(() => import("../Routes/TermsRoute"));
const FaqRouteComponent = lazy(() => import("../Routes/FaqRoute"));
const SupportRouteComponent = lazy(() => import("../Routes/SupportRoute"));

const store = configureStore({
  reducer: {
    auth: authReducer,
    flights: flightsRootReducer,
    cfar: cfarRootReducer,
    disruption: disruptionRootReducer,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Sets all queries to become stale after 1 min (RQ default is to make queries stale right away)
      staleTime: 60 * 1000, // 1 min
    },
  },
});

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <PortalCore config={config} store={store}>
      <LloydsProviders>
        <SlotProvider slots={APP_SLOTS}>
          <FeatureFlagsContextProvider featureFlags={config.featureFlags}>
            <Box className={styles.container}>
              <Header />
              <ErrorBoundary>
                <main className={styles.main}>
                  <Routes>
                    <Route
                      path={`${PATH_HOME}${PATH_AUTH}*`}
                      Component={AuthRoutes}
                    />
                    <Route
                      path={PATH_AGENT_IMPERSONATION}
                      element={<AgentImpersonation />}
                    />
                    <Route path={`${PATH_HOME}*`} Component={AppRoutes} />
                    <Route
                      path={PATH_TERMS}
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <TermsRouteComponent />
                        </Suspense>
                      }
                    />
                    <Route
                      path={`${PATH_FAQ}/*`}
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <FaqRouteComponent />
                        </Suspense>
                      }
                    />
                    <Route
                      path={PATH_SUPPORT}
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <SupportRouteComponent />
                        </Suspense>
                      }
                    />
                  </Routes>
                </main>
              </ErrorBoundary>
              <Footer />
            </Box>
          </FeatureFlagsContextProvider>
        </SlotProvider>
      </LloydsProviders>
    </PortalCore>
  </QueryClientProvider>
);
