import { Suggestion } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GuestsSelection } from "@hopper-b2b/types";
import { HotelLocationAutoComplete } from "../HotelLocationAutocomplete";
import { MobileFloatingButton } from "@hopper-b2b/ui";
import { GuestPickerInput } from "@lloyds/ui-connected";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
export type HotelSearchDestinationProps = {
  destination?: Suggestion;
  guestCount: GuestsSelection;
  initialLocation?: Suggestion;
  onOpen?: () => void;
  onClick: () => void;
  setGuestCount: React.Dispatch<React.SetStateAction<GuestsSelection>>;
  setDestination: React.Dispatch<React.SetStateAction<Suggestion>>;
  title?: string;
};

export const HotelSearchDestination = ({
  destination,
  guestCount,
  setDestination,
  setGuestCount,
  initialLocation,
  onOpen,
  onClick,
  title,
}: HotelSearchDestinationProps) => {
  const { t } = useI18nContext();

  return (
    <Grid container spacing={2} direction="row" alignItems="stretch">
      {title && (
        <Grid item xs={12} sm={3} lg={3}>
          <Typography variant="h3">{title}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={3} lg={3}>
        <GuestPickerInput
          guestCounts={guestCount}
          updateGuestCounts={setGuestCount}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <HotelLocationAutoComplete
          id="destination"
          label=""
          placeholder={t("lodging.search.whereAreYouStaying")}
          onChange={setDestination}
          onOpen={onOpen}
          defaultValue={initialLocation}
          className={clsx("lodging-autocomplete")}
          paperClassName={clsx("lodging-autocomplete-paper")}
          closeIcon={null}
        />
      </Grid>
      {destination && (
        <MobileFloatingButton
          floatingBoxClassName={clsx("lodging-search-floating")}
          onClick={onClick}
        >
          {t("lodging.search.selectDestinationButton")}
        </MobileFloatingButton>
      )}
    </Grid>
  );
};
