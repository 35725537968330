import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import {
  FiltersModalProps,
  FreeCancellation,
  sortAmenities,
  useTrackEvents,
} from "@hopper-b2b/lodging";
import { SortOption } from "@hopper-b2b/lodging-utils";
import { IPriceRange, LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { AmenitiesFilter, IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Accordion, LbgModal } from "@lloyds/ui-connected";
import { ActionButton, TextButton } from "@lloyds/ui-core";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import styles from "./FiltersModal.module.scss";

export function FiltersModal({
  open,
  filters: {
    priceRange,
    starRating,
    userRating,
    freeCancellation,
    amenities,
    filterAmenitiesAnd,
  },
  filterBoundaries,
  closeFiltersModal,
  onFilterPriceRangeChange,
  onStarRatingChange,
  onUserRatingChange,
  onFreeCancellationChange,
  onAmenitiesChange,
  onAmenitiesTypeChange,
  resetFilters,
  sort,
  setSort,
}: FiltersModalProps) {
  const { t } = useI18nContext();
  const trackEvent = useTrackEvents();
  const { matchesMobile } = useDeviceTypes();

  const [iStarRating, setIStarRating] = useState(starRating);
  const [iSort, setISort] = useState(sort);
  const [iUserRating, setIUserRating] = useState(userRating);
  const [iAmenities, setIAmenities] = useState(amenities);
  const [iAmenitiesType, setIAmenitiesType] = useState(filterAmenitiesAnd);
  const [iFreeCancellation, setIFreeCancellation] = useState(freeCancellation);
  const [iPriceRange, setIPriceRange] = useState({
    max: priceRange.max,
    min: priceRange.min,
  });

  useEffect(() => {
    if (open) {
      trackEvent(LodgingShopTrackingEvents.hotel_viewed_filters);
    }
  }, [open, trackEvent]);

  const resetFiltersInternal = useCallback(() => {
    setIStarRating(starRating);
    setIPriceRange({ max: priceRange.max, min: priceRange.min });
    setIUserRating(userRating);
    setIAmenities(amenities);
    setIAmenitiesType(filterAmenitiesAnd);
    setISort(sort);
    setIFreeCancellation(freeCancellation);
  }, [
    starRating,
    priceRange,
    userRating,
    amenities,
    filterAmenitiesAnd,
    sort,
    freeCancellation,
  ]);

  useEffect(() => {
    resetFiltersInternal();
  }, [resetFiltersInternal]);

  const handleApply = useCallback(() => {
    onStarRatingChange(iStarRating);
    onFilterPriceRangeChange(iPriceRange);
    onUserRatingChange(iUserRating);
    onAmenitiesChange(iAmenities);
    onAmenitiesTypeChange(iAmenitiesType);
    setSort(iSort);
    onFreeCancellationChange(iFreeCancellation);
    closeFiltersModal();
  }, [
    iStarRating,
    onStarRatingChange,
    iPriceRange,
    onFilterPriceRangeChange,
    iUserRating,
    onUserRatingChange,
    iAmenities,
    iAmenitiesType,
    onAmenitiesChange,
    onAmenitiesTypeChange,
    iSort,
    setSort,
    iFreeCancellation,
    onFreeCancellationChange,
    closeFiltersModal,
  ]);

  const onCancel = useCallback(() => {
    resetFiltersInternal();
    closeFiltersModal();
  }, [resetFiltersInternal, closeFiltersModal]);

  const onClose = useCallback(() => {
    if (matchesMobile) {
      handleApply();
    } else {
      onCancel();
    }
  }, [matchesMobile, handleApply, onCancel]);

  const onResetFilters = useCallback(() => {
    resetFilters();
    setSort(SortOption.MOST_RECOMMENDED);
    onCancel();
  }, [resetFilters, onCancel, setSort]);

  return (
    <LbgModal
      open={open}
      onClose={onClose}
      className={styles.container}
      title={
        <>
          {t("filters")}
          <Box ml={2} display="inline">
            <TextButton
              onClick={onResetFilters}
              message={t("clearAllFilters")}
            />
          </Box>
        </>
      }
      primaryBottomButton={
        !matchesMobile && (
          <ActionButton onClick={handleApply} message={"Apply filters"} />
        )
      }
      secondaryBottomButton={
        !matchesMobile && (
          <ActionButton
            defaultStyle="h4r-secondary"
            message={"Cancel"}
            onClick={onCancel}
          />
        )
      }
      diableContentPadding
    >
      <Box minWidth={matchesMobile ? "" : "680px"}>
        {matchesMobile ? (
          <Accordion title={t("sortBy")}>
            <SortInput className="sort" value={iSort} setSort={setISort} />
          </Accordion>
        ) : undefined}
        <Accordion title={t("starRating")}>
          <StarRating value={iStarRating} onChange={setIStarRating} />
        </Accordion>
        <Accordion title={t("priceRange")}>
          <PriceRange
            min={iPriceRange.min}
            max={iPriceRange.max}
            lowest={priceRange.lowest}
            highest={priceRange.highest}
            onFilterPriceRangeChange={setIPriceRange}
          />
        </Accordion>
        <Accordion title={t("amenities")}>
          <AmenitiesFilter
            amenities={filterBoundaries.amenities}
            andFilter={iAmenitiesType}
            appliedFilters={iAmenities}
            onChange={setIAmenities}
            onTypeChange={setIAmenitiesType}
            sortAmenities={sortAmenities}
          />
        </Accordion>
        <Accordion title={t("freeCancellation")}>
          <FreeCancellation
            freeCancellation={iFreeCancellation}
            onFreeCancellationChange={setIFreeCancellation}
          />
        </Accordion>
        <Accordion title={t("userRating")}>
          <UserRating value={iUserRating} onChange={setIUserRating} />
        </Accordion>
      </Box>
    </LbgModal>
  );
}

type StarRatingProps = {
  value: number[];
  onChange(nextValue: number[]): void;
};
function StarRating({ value, onChange }: StarRatingProps) {
  const { t } = useI18nContext();

  const options = [
    { toggleValue: [], stars: 0, content: t("any") },
    {
      toggleValue: [2, 3, 4, 5],
      stars: 2,
      ariaLabel: t("starsAndUp", { stars: 2 }),
    },
    {
      toggleValue: [3, 4, 5],
      stars: 3,
      ariaLabel: t("starsAndUp", { stars: 3 }),
    },
    { toggleValue: [4, 5], stars: 4, ariaLabel: t("starsAndUp", { stars: 4 }) },
    { toggleValue: [5], stars: 5, ariaLabel: t("starsAndUp", { stars: 5 }) },
  ];

  return (
    <div className={styles.StarRating}>
      <RadioGroup
        value={JSON.stringify(value)}
        onChange={(e) => {
          const value = JSON.parse(e.target.value);
          if (value != null) onChange(value);
        }}
        aria-label={t("starRating")}
      >
        {options.map(({ toggleValue, stars, content, ariaLabel }) => (
          <FormControlLabel
            key={stars}
            value={JSON.stringify(toggleValue)}
            aria-label={ariaLabel}
            control={<Radio color="primary" />}
            label={
              content ? (
                <Typography variant="subtitle1">{content}</Typography>
              ) : (
                <Rating value={stars} />
              )
            }
          />
        ))}
      </RadioGroup>
    </div>
  );
}

function Rating({ value }: { value: number }) {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle1">{value}</Typography>
      <IconComponent className={styles.stars} name={IconName.StarFilled} />
      <Typography variant="subtitle1">+</Typography>
    </Box>
  );
}

type SortProps = {
  value: SortOption;
  setSort(nextValue: SortOption): void;
};

export function SortInput({ value, setSort }: SortProps) {
  const { t } = useI18nContext();
  const trackEvent = useTrackEvents();

  const options: { value: SortOption; label: string }[] = [
    {
      value: SortOption.MOST_RECOMMENDED,
      label: t("mostRecommended"),
    },
    {
      value: SortOption.STAR_RATING,
      label: t("starRatingHighestFirst"),
    },
    {
      value: SortOption.USER_RATING,
      label: t("userRatingHighestFirst"),
    },
    {
      value: SortOption.PRICE,
      label: t("priceLowestFirst"),
    },
  ];

  const onChange = useCallback(
    (event) => {
      setSort(event.target.value as SortOption);
      trackEvent(LodgingShopTrackingEvents.hotel_updated_list_page);
    },
    [setSort, trackEvent]
  );

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      onClick={() => trackEvent(LodgingShopTrackingEvents.hotel_tapped_sort)}
      select
    >
      {options.map((option) => (
        <FormControlLabel
          control={<Radio color="primary" />}
          key={option.value}
          value={option.value}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
}

const StyledSlider = withStyles({
  root: {
    marginTop: "20px",
    height: 10,
    maxWidth: "90%",
  },
  thumb: {
    height: 18,
    width: 18,
    border: "2px solid var(--white)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.10)",
  },
  valueLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    "& *": {
      background: "transparent",
      color: "currentColor",
    },
  },
  track: {
    height: 8,
    borderRadius: "var(--border-radius)",
  },
  rail: {
    color: "var(--gray-30)",
    height: 8,
    borderRadius: "var(--border-radius)",
  },
})(Slider);

type PriceRangeProps = {
  min: number;
  max: number;
  lowest: number;
  highest: number;
  onFilterPriceRangeChange: (
    nextValue: Pick<IPriceRange, "min" | "max">
  ) => void;
};

const optionsToHideFractionDigits = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
};

export const PriceRange = ({
  min,
  max,
  lowest,
  highest,
  onFilterPriceRangeChange,
}: PriceRangeProps) => {
  const { t } = useI18nContext();
  const { formatCurrency } = useI18nCurrency();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">
        {/*  TODO: add this to i18n*/}
        {"Price per night before fees and taxes"}
      </Typography>
      <Box display={"flex"} justifyContent="center">
        <StyledSlider
          aria-label={t("priceRange")}
          value={[min, max]}
          min={lowest}
          max={highest}
          step={5}
          valueLabelDisplay="on"
          valueLabelFormat={(price: number) =>
            formatCurrency(price, optionsToHideFractionDigits)
          }
          onChange={(_event, nextValue: number | number[]) => {
            if (Array.isArray(nextValue)) {
              const [min, max] = nextValue;
              onFilterPriceRangeChange({ min, max });
            }
          }}
        />
      </Box>
    </div>
  );
};

type UserRatingProps = {
  value: number;
  onChange(nextValue: number): void;
};

function UserRating({ value, onChange }: UserRatingProps) {
  const { t } = useI18nContext();

  const options = [
    {
      toggleValue: 0,
      content: t("any"),
    },
    {
      toggleValue: 0.7,
      content: t("veryGood"),
    },
    {
      toggleValue: 0.9,
      content: t("excellent"),
    },
  ];

  return (
    <div className={styles.UserRating}>
      <RadioGroup
        value={value}
        onChange={(e) => {
          const nextValue = e.target.value;
          if (nextValue != null) onChange(Number(nextValue));
        }}
        aria-label={t("userRating")}
      >
        {options.map(({ toggleValue, content }) => (
          <FormControlLabel
            key={toggleValue}
            selected={value === toggleValue}
            value={toggleValue}
            control={<Radio color="primary" />}
            label={content}
          >
            {content}
          </FormControlLabel>
        ))}
      </RadioGroup>
    </div>
  );
}
