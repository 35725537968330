import { IconNameEnum } from "@b2bportal/core-themes";
import { FiatPrice } from "@b2bportal/purchase-api";
import { CheckoutQueryParams, ParentState } from "@checkout/index";
import { Icon } from "@components/ui";
import {
  PATH_AUTH,
  PATH_HOME,
  PATH_TRIPS,
  PATH_WALLET,
} from "@hopper-b2b/common-utils";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink, Slot } from "@hopper-b2b/ui";
import {
  PATH_AVAILABILITY_RELATIVE,
  PATH_BOOK_RELATIVE,
  useDeviceTypes,
  useIsSessionAuthenticated,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { FEATURE_FLAGS } from "@lloyds/theming";
import { ActionButton } from "@lloyds/ui-core";
import {
  LLOYDS_URL_PARAMS,
  PATH_SUPPORT,
  useIsBookingConfirmationPath,
} from "@lloyds/utils";
import { useWallet } from "@lloyds/wallet";
import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom-v5-compat";
import styles from "./Header.module.scss";
import {
  HeaderBackButton,
  HeaderLogo,
  HeaderNavigation,
  HeaderTitle,
  HeaderWalletInfo,
} from "./components";

export interface HeaderProps {
  title?: string;
  subtitle?: string;
  onBack?: () => void;
  rightItem?: ReactNode;
}

export const Header = ({ title, subtitle, onBack, rightItem }: HeaderProps) => {
  const { t } = useI18nContext();
  const outageBannerEnabled = useExperiment(FEATURE_FLAGS.OUTAGE_BANNER);
  return (
    <AppBar>
      <Container disableGutters maxWidth="xl">
        <Toolbar>
          <Box py={{ md: 3 }} width="100%">
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Slot
                  id="header-left-item"
                  component={<HeaderLeftItem onBack={onBack} />}
                />
              </Grid>

              <Grid item xs />

              <Grid item>
                <HeaderCenterItem title={title} subtitle={subtitle} />
              </Grid>

              <Grid item xs />

              <Grid item>{rightItem ? rightItem : <HeaderRightItem />}</Grid>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
      <Box className={styles.walletContainer}>
        <Container disableGutters maxWidth="xl">
          <HeaderWallet />
        </Container>
      </Box>
      <Slot
        id={"default-outage-banner"}
        enabled={outageBannerEnabled}
        bannerTitle={t("outageBannerTitle")}
        bannerSubtitle={t("outageBannerSubtitle")}
      />
    </AppBar>
  );
};

export const HeaderLeftItem = ({ onBack }: Pick<HeaderProps, "onBack">) => {
  const { matchesMobile } = useDeviceTypes();

  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const checkoutState = new URLSearchParams(search).get(
    CheckoutQueryParams.checkoutState
  );

  const isBackable = useMemo(() => {
    if (checkoutState === ParentState.bookingConfirmation) return false;

    if (pathname.includes(PATH_AUTH) || pathname.includes(PATH_SUPPORT)) {
      return false;
    }

    if (!pathname.includes(PATH_TRIPS)) {
      return true;
    }

    const showBackButton =
      searchParams.get(LLOYDS_URL_PARAMS.SHOW_BACK_BUTTON) === "true";
    if (showBackButton) {
      return true;
    }
    return false;
  }, [checkoutState, pathname, searchParams]);

  return matchesMobile ? (
    pathname === PATH_HOME ? (
      <HeaderLogo />
    ) : isBackable ? (
      <HeaderBackButton onBack={onBack} />
    ) : null
  ) : (
    <HeaderLogo />
  );
};

const HeaderCenterItem = ({
  title,
  subtitle,
}: Pick<HeaderProps, "title" | "subtitle">) => {
  const { matchesMobile } = useDeviceTypes();

  const { pathname } = useLocation();

  const isBookingConfirmation = useIsBookingConfirmationPath();

  const showNavigation = useMemo(
    () =>
      pathname === PATH_HOME ||
      pathname === PATH_TRIPS ||
      pathname === PATH_WALLET ||
      isBookingConfirmation,
    [pathname, isBookingConfirmation]
  );

  return matchesMobile ? (
    <Box textAlign="center">
      <Typography noWrap variant="inherit" className={styles.title}>
        <Slot id="header-title" component={title ?? <HeaderTitle />} />
      </Typography>
      <Typography variant="caption">
        <Slot id="header-subtitle" component={subtitle} />
      </Typography>
    </Box>
  ) : (
    showNavigation && <HeaderNavigation />
  );
};

const HeaderRightItem = () => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <Box textAlign="end">
      <Slot id="header-extra-action" />
    </Box>
  ) : (
    <HeaderWalletInfo />
  );
};

const HeaderWallet = () => {
  const isAuthenticated = useIsSessionAuthenticated();
  const isWalletEnabled = useExperiment(FEATURE_FLAGS.WALLET);
  const { matchesMobile } = useDeviceTypes();
  const { pathname } = useLocation();
  const { creditBalance, isLoadingWallet, fetchAndSetWalletSummary } =
    useWallet();

  const [showWalletBanner, setShowWalletBanner] = useState<boolean>(true);

  useEffect(() => {
    isAuthenticated && isWalletEnabled && fetchAndSetWalletSummary();
  }, [fetchAndSetWalletSummary, isAuthenticated, isWalletEnabled]);

  const sanitizePath = (path: string) => path.replaceAll("/", "");

  const showBanner = useMemo(() => {
    const isValidPathname = matchesMobile
      ? pathname === PATH_HOME
      : !pathname.includes(sanitizePath(PATH_BOOK_RELATIVE)) &&
        !pathname.includes(sanitizePath(PATH_AVAILABILITY_RELATIVE)) &&
        !pathname.includes(sanitizePath(PATH_WALLET));
    return (
      isValidPathname &&
      showWalletBanner &&
      creditBalance?.value > 0 &&
      !isLoadingWallet
    );
  }, [
    pathname,
    showWalletBanner,
    creditBalance,
    isLoadingWallet,
    matchesMobile,
  ]);

  return (
    showBanner &&
    (matchesMobile ? (
      <Box className={styles.mobileBanner}>
        <HeaderWalletLeftItem creditBalance={creditBalance} />
      </Box>
    ) : (
      <Box className={styles.desktopBanner}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <HeaderWalletLeftItem creditBalance={creditBalance} />
          </Grid>

          <Grid item xs />

          <Grid item>
            <HeaderCloseItem onClose={() => setShowWalletBanner(false)} />
          </Grid>
        </Grid>
      </Box>
    ))
  );
};

const HeaderWalletLeftItem = ({
  creditBalance,
}: {
  creditBalance: FiatPrice;
}) => {
  const { matchesMobile } = useDeviceTypes();
  const { formatFiatCurrency, t } = useI18nContext();
  const navigate = useNavigate();

  const absAmount = useMemo(() => {
    if (creditBalance) {
      return {
        ...creditBalance,
        value: Math.abs(creditBalance.value),
      };
    }
  }, [creditBalance]);

  const toWallet = useCallback(() => {
    navigate(PATH_WALLET);
  }, [navigate]);

  return matchesMobile ? (
    <Box
      display="flex"
      flexDirection="row"
      gridGap="12px"
      alignItems="flex-start"
    >
      <Icon iconName={IconNameEnum.credits} className={styles.icon} />
      <Typography
        variant="subtitle1"
        style={{ color: "var(--primary-2)", lineHeight: "18px" }}
      >
        {t("wallet.headerBanner", { amount: formatFiatCurrency(absAmount) })}
      </Typography>
    </Box>
  ) : (
    <Box display="flex" flexDirection="row" gridGap="12px" alignItems="center">
      <Icon iconName={IconNameEnum.credits} className={styles.icon} />
      <Typography variant="subtitle1" style={{ color: "var(--primary-2)" }}>
        {t("wallet.headerBanner", { amount: formatFiatCurrency(absAmount) })}
      </Typography>
      <ActionButton
        message={t("viewDetails")}
        onClick={toWallet}
        defaultStyle="h4r-secondary"
        className={styles.button}
      />
    </Box>
  );
};

const HeaderCloseItem = ({ onClose }: { onClose: () => void }) => {
  const icons = useTenantIcons();

  return (
    <Box width={24} height={24}>
      <ActionLink
        content={<img src={icons.close} alt="" />}
        label="Close"
        onClick={onClose}
      />
    </Box>
  );
};
