import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_HOME, PATH_TRIPS } from "@hopper-b2b/utilities";
import { Box, Grid, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom-v5-compat";
import styles from "./HeaderNavigation.module.scss";

export const HeaderNavigation = () => {
  const { t } = useI18nContext();
  const location = useLocation();

  const [indexSelected, setIndexSelected] = useState<number>(0);

  const titles = useMemo(
    () => [
      { text: t("goHome"), path: PATH_HOME },
      { text: t("myTrips"), path: `${PATH_HOME}${PATH_TRIPS}` },
    ],
    []
  );

  useEffect(() => {
    titles.forEach(({ path }, index) => {
      if (path === location.pathname && indexSelected !== index) {
        setIndexSelected(index);
      }
    });
  }, [titles, setIndexSelected, location, indexSelected]);

  return (
    <Grid container alignContent="center" justifyContent="center">
      {titles.map(({ text, path }, index) => (
        <Grid key={String(index) + path} item>
          <Link to={path} className={styles.navigationLink}>
            <Box px={2} py={2}>
              <Typography
                style={{
                  fontWeight: index === indexSelected ? 700 : 400,
                }}
                variant="subtitle1"
              >
                {text}
              </Typography>
            </Box>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
