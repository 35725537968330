import { ErrorBannerProps as CommonErrorBannerProps } from "@hopper-b2b/ui";
import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import styles from "./ErrorBanner.module.scss";
import { ReactComponent as InfoIcon } from "./info.svg";

type ErrorBannerProps = CommonErrorBannerProps & {
  extraAction?: React.ReactNode;
};

export const ErrorBanner = ({
  title,
  subtitle,
  extraAction,
}: ErrorBannerProps) =>
  (title || subtitle) && (
    <Box mb={3} className={styles.container}>
      <Grid container spacing={3}>
        <Grid item>
          <Box mt={2}>
            <InfoIcon />
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          <Typography color="inherit">{subtitle}</Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <Box textAlign="right">{extraAction}</Box>
        </Grid>
      </Grid>
    </Box>
  );
