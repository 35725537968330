import { useDeviceType } from "@b2bportal/core-utilities";
import {
  ActiveSearchContext,
  ActiveSearchHandlers,
} from "@b2bportal/core-flights";
import { MobileFlightSearch } from "./MobileFlightSearch";
import { DesktopFlightSearch } from "./DesktopFlightSearch";
import { Box, Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";

export type FlightSearchComponentProps = ActiveSearchContext &
  ActiveSearchHandlers;

export const FlightSearch = () => {
  const { isDesktopAndUp } = useDeviceType();

  const { t } = useI18nContext();

  return isDesktopAndUp ? (
    <DesktopFlightSearch />
  ) : (
    <Box>
      <Box marginBottom={4} marginTop={4}>
        <Typography variant="h3">
          {t("flightsPageTitles.searchFlights")}
        </Typography>
      </Box>

      <MobileFlightSearch />
    </Box>
  );
};
