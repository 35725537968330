import { Suggestion } from "@b2bportal/lodging-api";
import { useIsRoomsEnabled } from "@hopper-b2b/common-search";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  buildSearchParams,
  placeLabelAndSublabelToUrl,
} from "@hopper-b2b/lodging-utils";
import { GuestsSelection, TripCategory } from "@hopper-b2b/types";
import { PATH_HOTELS_BOOK_AVAILABILITY } from "@hopper-b2b/utilities";
import {
  GuestPickerInput,
  CalendarPickerButton,
  HotelCalendarPicker,
} from "@lloyds/ui-connected";
import { ActionButton } from "@lloyds/ui-core";
import { Box, Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HotelLocationAutoComplete } from "../HotelLocationAutocomplete";

export type HotelSearchProps = {
  initialAdultGuests?: number;
  initialChildGuests?: number[];
  initialRooms?: number;
  initialLocation?: Suggestion;
  initialCheckinDate?: string;
  initialCheckoutDate?: string;
  enableRooms?: boolean;
  onSearch?: (path: string) => void;
  onOpen?: () => void;
};

export const DesktopHotelSearch = ({
  initialAdultGuests = 2,
  initialChildGuests = [],
  initialRooms = 1,
  initialLocation,
  initialCheckinDate,
  initialCheckoutDate,
  enableRooms: enableRoomsProps = true,
  onSearch,
  onOpen,
}: HotelSearchProps) => {
  const { t } = useI18nContext();

  const enableRoomsFlag = useIsRoomsEnabled();
  const enableRooms = enableRoomsProps ?? enableRoomsFlag;

  const [destination, setDestination] = useState<Suggestion | undefined>(
    initialLocation
  );
  const [checkinDate, setCheckinDate] = useState<string | undefined>(
    initialCheckinDate
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    initialCheckoutDate
  );
  const [guestCount, setGuestCount] = useState<GuestsSelection>({
    adults: initialAdultGuests,
    children: initialChildGuests,
    rooms: initialRooms,
  });

  useEffect(() => {
    setDestination(initialLocation);
  }, [initialLocation]);

  const destinationCode = useMemo(() => {
    if (destination) {
      const { label, subLabel } = destination;
      return placeLabelAndSublabelToUrl(label, subLabel);
    }
    return undefined;
  }, [destination]);

  const isSearchReady = useMemo(
    () => destination && checkinDate && checkoutDate,
    [checkinDate, checkoutDate, destination]
  );

  const searchUrl = useMemo(() => {
    const searchParams = buildSearchParams({
      fromDate: checkinDate,
      untilDate: checkoutDate,
      adultsCount: guestCount.adults,
      childrenCount: guestCount.children,
      roomsCount: guestCount.rooms,
      enableRooms,
    });

    return `${PATH_HOTELS_BOOK_AVAILABILITY}${destinationCode}?${searchParams.toString()}`;
  }, [
    guestCount.adults,
    guestCount.children,
    guestCount.rooms,
    checkinDate,
    checkoutDate,
    destinationCode,
    enableRooms,
  ]);

  const handleSearch = useCallback(() => {
    if (onSearch) {
      onSearch(searchUrl);
    } else {
      window.location.href = searchUrl;
    }
  }, [onSearch, searchUrl]);

  return (
    <Grid container spacing={3} direction="row" alignItems="stretch">
      <Grid item xs={12} lg={4}>
        <HotelLocationAutoComplete
          id="destination"
          label=""
          onChange={setDestination}
          onOpen={onOpen}
          defaultValue={initialLocation}
          placeholder={t("lodging.search.whereAreYouStaying")}
          value={destination}
          closeIcon={null}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4} className="hotel-search-container">
        <CalendarPickerButton
          startDate={checkinDate ?? null}
          endDate={checkoutDate ?? null}
          startLabel={t("checkin") ?? undefined}
          endLabel={t("checkout") ?? undefined}
          setStartDate={setCheckinDate}
          setEndDate={setCheckoutDate}
          CalendarComponent={HotelCalendarPicker}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        <GuestPickerInput
          guestCounts={guestCount}
          updateGuestCounts={setGuestCount}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        lg={2}
        className="hotel-search-button-container"
      >
        <Box height="100%" display={"flex"}>
          <ActionButton
            onClick={handleSearch}
            disabled={!isSearchReady}
            size="medium"
            message={t("searchButton")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
