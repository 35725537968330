import { axiosInstance } from "@hopper-b2b/api";
import { config } from "@lloyds/utils";
import { useEffect, useRef } from "react";

const TRAVEL_SUBDOMAIN = "travel";

const buildKeepaliveUrl = (url: string) => {
  const keepaliveUrl = new URL(url);
  const hostnameParts = keepaliveUrl.hostname.split(".");

  // Strip out TRAVEL_SUBDOMAIN from Hostname
  const keepaliveParts = hostnameParts.filter(
    (currentHostname) => currentHostname !== TRAVEL_SUBDOMAIN
  );

  // Construct Keepalive URL
  keepaliveUrl.hostname = keepaliveParts.join(".");
  keepaliveUrl.pathname = config.keepaliveUri;

  // Clear out search params
  new URLSearchParams(keepaliveUrl.searchParams).forEach(
    (_value: string, key: string) => {
      keepaliveUrl.searchParams.delete(key);
    }
  );

  return keepaliveUrl.toString();
};

const doKeepalive = async () => {
  const keepaliveUrl = buildKeepaliveUrl(window.location.href);

  try {
    await axiosInstance.get(keepaliveUrl);
  } catch (e) {
    console.warn("Error happened with the keepalive", e);
    // DO NOTHING
  }
};

export const useKeepalive = () => {
  const keepaliveTimer = useRef(null);

  useEffect(() => {
    if (keepaliveTimer.current) {
      clearInterval(keepaliveTimer.current);
    }

    keepaliveTimer.current = setInterval(doKeepalive, config.keepaliveTimeout);

    return () => {
      clearInterval(keepaliveTimer.current);
    };
  }, []);
};
