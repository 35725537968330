import { useI18nContext } from "@hopper-b2b/i18n";
import { FlightSearchDestination } from "../FlightSearchDestination";
import { Dialog } from "@material-ui/core";
import { useCallback, useState } from "react";
import { FlightCalendarPicker, HighContrastToggle } from "@lloyds/ui-connected";
import { Header } from "@lloyds/header";
import { FlightSearchHooks, useFlightSearch } from "@components/flights";

export const MobileFlightSearch = ({
  onSearchProp,
}: {
  onSearchProp?: () => void;
}) => {
  const { t } = useI18nContext();
  const [isCalendarStep, setIsCalendarStep] = useState(false);
  const {
    context: {
      tripCategory,
      passengerCounts,
      origin,
      destination,
      departureDate,
      returnDate,
      validSearchParams,
    },
    handlers: {
      onDepartureDateChange,
      onDestinationChange,
      onOriginChange,
      onPassengerCountChange,
      onReturnDateChange,
      onTripCategoryChange,
      onSearch,
    },
  } = useFlightSearch();

  const onSearchClick = useCallback(() => {
    setIsCalendarStep(true);
  }, []);

  const onCalendarBack = useCallback(() => {
    setIsCalendarStep(false);
  }, []);

  const handleSearch = () => {
    onSearchProp ? onSearchProp?.() : onSearch(validSearchParams);
    setIsCalendarStep(false);
  };

  const { departureMonths, priceTags } = FlightSearchHooks.useFetchCalendar({
    origin,
    destination,
    tripCategory,
  });

  return (
    <>
      <FlightSearchDestination
        origin={origin}
        destination={destination}
        setOrigin={onOriginChange}
        setDestination={onDestinationChange}
        passengerCount={passengerCounts}
        setPassengerCount={onPassengerCountChange}
        tripCategory={tripCategory}
        setTripCategory={onTripCategoryChange}
        onClick={onSearchClick}
      />
      <Dialog open={isCalendarStep} fullWidth fullScreen transitionDuration={0}>
        <Header
          title={t("calendarTitle")}
          onBack={onCalendarBack}
          rightItem={<HighContrastToggle />}
        />
        <FlightCalendarPicker
          tripCategory={tripCategory}
          startDate={departureDate}
          endDate={returnDate}
          priceTags={priceTags}
          months={departureMonths}
          setStartDate={onDepartureDateChange}
          setEndDate={onReturnDateChange}
          onComplete={handleSearch}
        />
      </Dialog>
    </>
  );
};
