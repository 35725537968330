import { useI18nContext } from "@hopper-b2b/i18n";
import { LbgModal } from "../../LbgModal";
import { TextInputField } from "../../TextInputField";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";
import { Box, InputAdornment } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import styles from "./PassengerPickerInput.module.scss";
import { CountPicker } from "../components/CountPicker";
import { ActionButton } from "@lloyds/ui-core";
import { IPassengerCounts } from "@b2bportal/core-types";
import { useSearchParams } from "react-router-dom-v5-compat";
import { LLOYDS_URL_PARAMS } from "@lloyds/utils";

export type PassengerPickerInputProps = {
  paxCounts: IPassengerCounts;
  updatePaxCounts: (count: IPassengerCounts) => void;
};

const MINIMUM_ADULTS_COUNT = 1;
const MAXIMUM_COUNT = 6;

export const PassengerPickerInput = ({
  paxCounts,
  updatePaxCounts,
}: PassengerPickerInputProps) => {
  const { t } = useI18nContext();
  const tenantIcons = useTenantIcons();
  const [showPaxCountPicker, setShowPaxCountPicker] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { matchesMobile } = useDeviceTypes();
  const texts = {
    adultTitle: t("flight.search.passengerPicker.adultTitle"),
    adultSubtitle: t("flight.search.passengerPicker.adultSubtitle"),
    youthTitle: t("flight.search.passengerPicker.youthTitle"),
    youthSubtitle: t("flight.search.passengerPicker.youthSubtitle"),
    childrenTitle: t("flight.search.passengerPicker.childrenTitle"),
    childrenSubtitle: t("flight.search.passengerPicker.childrenSubtitle"),
    infantsOnLapTitle: t("flight.search.passengerPicker.infantLapTitle"),
    infantsOnLapSubtitle: t("flight.search.passengerPicker.infantLapSubtitle"),
    infantsInSeatTitle: t("flight.search.passengerPicker.infantSeatTitle"),
    infantsInSeatSubtitle: t(
      "flight.search.passengerPicker.infantSeatSubtitle"
    ),
    applyButtonText: t("flight.search.passengerPicker.apply"),
  };

  const [adultsCount, setAdultsCount] = useState(paxCounts.adultsCount);
  const [childrenCount, setChildrenCount] = useState(paxCounts.childrenCount);
  const [infantsOnLapCount, setInfantsOnLapCount] = useState(
    paxCounts.infantsOnLapCount
  );
  const [infantsInSeatCount, setInfantsInSeatCount] = useState(
    paxCounts.infantsInSeatCount
  );
  const youthParam = useMemo(() => {
    const param = Number(searchParams.get(LLOYDS_URL_PARAMS.YOUTH));

    if (isNaN(param)) {
      searchParams.delete(LLOYDS_URL_PARAMS.YOUTH);
      setSearchParams(searchParams);
      return 0;
    } else if (adultsCount - param < 1) {
      searchParams.delete(LLOYDS_URL_PARAMS.YOUTH);
      setSearchParams(searchParams);
      return 0;
    }
    return param;
  }, [searchParams, setSearchParams, adultsCount]);

  const [youthCount, setYouthCount] = useState(youthParam);

  const handleChangeAdultCount = useCallback(
    (num: number) => {
      setAdultsCount(num + youthCount);
    },
    [setAdultsCount, youthCount]
  );
  const handleChangeYouthCount = useCallback(
    (num: number) => {
      setAdultsCount(adultsCount + num - youthCount);
      setYouthCount(num);
    },
    [setAdultsCount, adultsCount, setYouthCount, youthCount]
  );

  const maximumReached =
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount >=
    MAXIMUM_COUNT;

  const handleApplyClick = useCallback(() => {
    if (youthCount > 0) {
      searchParams.set(LLOYDS_URL_PARAMS.YOUTH, `${youthCount}`);
      setSearchParams(searchParams);
    } else if (youthCount === 0) {
      searchParams.delete(LLOYDS_URL_PARAMS.YOUTH);
      setSearchParams(searchParams);
    }
    updatePaxCounts({
      adultsCount: adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    });
    setShowPaxCountPicker(false);
  }, [
    searchParams,
    setSearchParams,
    adultsCount,
    youthCount,
    childrenCount,
    infantsOnLapCount,
    infantsInSeatCount,
    updatePaxCounts,
  ]);
  const numPax = useMemo(
    () => Object.values(paxCounts).reduce((a, b) => a + b, 0),
    [paxCounts]
  );

  const handleClose = useCallback(() => {
    setShowPaxCountPicker(false);
  }, []);

  const onInputClick = useCallback(() => {
    setShowPaxCountPicker(true);
  }, []);

  return (
    <>
      <TextInputField
        clearable={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                height={16}
                width={16}
                className={styles.tenantIcon}
                src={tenantIcons.guest}
                alt=""
              />
            </InputAdornment>
          ),
        }}
        onClick={onInputClick}
        value={`${t("searchFilter.pickerTraveler", {
          count: numPax,
        })}`}
        aria-label={t("passengerPicker")}
      />
      <LbgModal
        open={showPaxCountPicker}
        onClose={handleClose}
        title={t("flight.search.passengerPicker.modalTitle")}
        subtitle={t("flight.search.passengerPicker.warningMessage", {
          maximumCount: 6,
        })}
        primaryBottomButton={
          <ActionButton
            onClick={handleApplyClick}
            message={texts.applyButtonText}
          />
        }
        diableContentPadding
      >
        <Box width={matchesMobile ? "unset" : "350px"}>
          <Box>
            <CountPicker
              title={texts.adultTitle}
              subtitle={texts.adultSubtitle}
              maximumReached={maximumReached}
              minimumCount={MINIMUM_ADULTS_COUNT}
              count={adultsCount - youthCount}
              setCount={handleChangeAdultCount}
            />
            <CountPicker
              title={texts.youthTitle}
              subtitle={texts.youthSubtitle}
              maximumReached={maximumReached}
              count={youthCount}
              setCount={handleChangeYouthCount}
            />
            <CountPicker
              title={texts.childrenTitle}
              subtitle={texts.childrenSubtitle}
              maximumReached={maximumReached}
              count={childrenCount}
              setCount={setChildrenCount}
            />
            <CountPicker
              title={texts.infantsInSeatTitle}
              subtitle={texts.infantsInSeatSubtitle}
              maximumReached={maximumReached}
              count={infantsInSeatCount}
              setCount={setInfantsInSeatCount}
            />
            <CountPicker
              title={texts.infantsOnLapTitle}
              subtitle={texts.infantsOnLapSubtitle}
              maximumReached={maximumReached}
              count={infantsOnLapCount}
              setCount={setInfantsOnLapCount}
            />
          </Box>
        </Box>
      </LbgModal>
    </>
  );
};
